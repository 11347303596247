<template lang="html">
    <div id="login">
        <div class="bg">
            <!-- <p>电信基站管理系统</p> -->
            <div class="loginBox" @keyup.enter="loginClick">
                <!-- <img src="../assets/logo.png" alt=""> -->
                <p>天缘汽配数字化<br>经营管理系统</p>
                <input class="user" type="text" v-model="name" placeholder="请输入用户名">
                <input class="pwd" type="password" v-model="pwd" placeholder="请输入密码">
                <div class="code">
                    <input type="text" v-model="yzm" placeholder="请输入验证码">
                    <img :src="captchaSrc" @click="flushCaptcha" />
                </div>
                <button @click="loginClick()">登录</button>
            </div>
            <h4>技术支持&copy;衡水云翼信息技术有限公司</h4>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data: function() {
        return {
            name: '',
            pwd: '',
            yzm: '',
            captchaSrc: this.domain + '/captcha'
        }
    },
    methods: {
        flushCaptcha: function() {
            this.captchaSrc = this.domain + '/captcha' + '?' + Math.random()
        },
        loginClick: function() {
            if (!this.name) {
                this.$message.error('请输入用户名');
            } else if (!this.pwd) {
                this.$message.error('请输入密码');
            } else if (!this.yzm) {
                this.$message.error('请输入验证码');
            } else {
                axios.post(this.domain + '/login/login', {
                    username: this.name,
                    password: this.$md5(this.pwd),
                    yzm: this.yzm,
                    device:'PC'
                }).then(res => {
                    var _token=res.data._token;
                    sessionStorage.setItem('_token',_token);
                    if (res.data.code == 200) {
                        this.$message({
                            message: '登录成功',
                            type: 'success'
                        });
                        sessionStorage.setItem('userId', res.data.data.id);
                        if(res.data.data.site_id==0){
                            this.$router.push({
                                name:'统计概况',
                                params:{
                                    form:'login'
                                }
                            });
                        }else{
                            this.$router.push({
                                name:'站点统计概况',
                                params:{
                                    form:'login'
                                }
                            });
                        }
                    } else {
                        this.$message.error(res.data.message);
                        this.flushCaptcha();
                        this.yzm='';
                    }
                }).catch(function(error) { // 请求失败处理
                    console.log(error);
                });
            }
        }
    }
}
</script>

<style lang="css" scoped>
@media screen and (max-width: 800px) {
    #login .bg .loginBox input{
        margin-bottom: 10px;
    }
}
</style>
